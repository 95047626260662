@font-face {
  font-family: "assistant";
  src: url("./fonts/Assistant-ExtraBold.ttf") format("ttf");
  /* Add other formats as needed */
}
@font-face {
  font-family: "osh";
  src: url("./fonts/OpenSansHebrew-ExtraBold.ttf") format("ttf");
  /* Add other formats as needed */
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.background {
  background-image: url("./background.png"); /* or the correct path to your image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
}

.content {
  text-align: center; /* Center text and image horizontally */
  /* Add any additional styling here */
}

.content img {
  width: 350px; /* Adjust this value to increase the logo size */
  height: auto; /* This ensures the image maintains its aspect ratio */
}

.content h1 {
  margin-bottom: 20px; /* Adjust as needed */
  font-family: "assistant", sans-serif;
  color: white;
}

.error-container {
  position: relative;
  width: 200px; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
}

.error-container img {
  width: 100%;
  height: 100%;
}

.error-message {
  font-family: "assistant", sans-serif;
  color: #130f4d;
  font-size: 35px;
  position: absolute; /* Absolute positioning for the text */
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Centering the text inside the container */
}
.deletion-form-container {
  max-width: 400px;
  margin: auto;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"] {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #007bff; /* Bootstrap blue */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button[type="submit"]:hover {
  background-color: #0056b3; /* Darker blue */
}
